import { invokeApi } from "src/utils";

export const _get_website_settings = () => {
  const requestObj = {
    path: `api/website_setting/get_website_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _edit_website_settings = (data) => {
  const requestObj = {
    path: `api/website_setting/edit_website_setting`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
