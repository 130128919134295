import { invokeApi } from "src/utils";

export const _get_payment_plans = () => {
  const requestObj = {
    path: `api/subscription/get_subscription_list`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const _add_payment_plan = (data) => {
  const requestObj = {
    path: `api/subscription/add_subscription_plan`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _edit_payment_plan = (data) => {
  const requestObj = {
    path: `api/subscription/update_subscription_plan`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
