import { invokeApi } from "src/utils";
export const _get_users_list = (page, limit, data) => {
  const requestObj = {
    path: `api/admin/subscriber_list?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const _get_user_detail = (id) => {
  const requestObj = {
    path: `api/customer/workspace_customer_detail_for_admin/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const _change_user_plan = (data) => {
  const requestObj = {
    path: `api/subscription/change_subscription_plan_for_single_user`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
