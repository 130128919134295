import { invokeApi } from "src/utils";

export const _login = async (data) => {
  const token = await _generate_token();
  const requestObj = {
    path: `api/app_api/login`,
    method: "POST",
    headers: {
      "x-sh-token": token.token,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const logout = async (data) => {
  const requestObj = {
    path: `api/examplePath`,
    method: "POST",
    headers: {
      // 'x-sh-auth': 1234
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const _generate_token = () => {
  const requestObj = {
    path: `api/app_api/send_verification_token`,
    method: "POST",
    postData: { code: "verify" },
  };
  return invokeApi(requestObj);
};
